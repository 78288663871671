.App {
  position: relative;
  text-align: center;
  background-color: #000;
  height: 100vh;
  padding: 47vh 0 0;
}

.content {
  margin: auto;
  background-color: #000;
  transform: translateY(-50%);
  width: 216px;
}
.content-icon {
  margin: 0 0 32px;
}

.content-warning-icon {
  color: #fff;
  margin: auto;
  padding: 2px 0 0;
  border-radius: 50%;
  border: solid 2px #fff;
  width: 40px;
  height: 40px;
  font-size: 26px;
}
.selector {
  color: #000;
}
.content-header {
  margin: 0 0 40px;
  background-color: #000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  font-family: Noto Sans CJK KR;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  color: #fff;
  font-weight: 700;
}
.warning-content {
  width: 300px;
}
.warning-header {
  margin: 32px 0 74px;
}
.content-inputs {
  margin: 0 0 32px;
  background-color: #000;
}
.content-link {
  color: #61dafb;
}
.button {
  background: linear-gradient(270deg, #519fe5 0%, #ef5e80 100%);
  border: none;
  border-radius: 19px;
  width: 100%;
  height: 38px;
  font-weight: 500;
  font-size: 14px;
  color: #fff;
}

sup,
sub {
  vertical-align: baseline;
  position: relative;
  top: -0.4em;
}

.content-detail {
  color: #868686;
  text-align: left;
  width: 216px;
  margin: auto;
}
